export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const FORM_LOADING = "FORM_LOADING";
export const IP_LOADING = "FORM_LOADING";
export const POOL_LOADING = "POOL_LOADING";
export const STAKE_LOADING = "STAKE_LOADING"
export const LAUNCHPAD_LOADING = "LAUNCHPAD_LOADING"
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const USER_ADD = "USER_ADD";
export const USER_UPDATE = "USER_UPDATE";

export const FORM_ADD = "FORM_ADD";
export const FORM_UPDATE = "FORM_UPDATE";

export const POOL_ADD = "POOL_ADD";
export const POOL_UPDATE = "POOL_UPDATE";

export const IP_ADD = "IP_ADD";
export const IP_UPDATE = "IP_UPDATE";

export const STAKE_ADD = "STAKE_ADD";
export const STAKE_UPDATE = "STAKE_UPDATE";

export const LAUNCHPAD_ADD = "LAUNCHPAD_ADD";
export const LAUNCHPAD_UPDATE = "LAUNCHPAD_UPDATE";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../actions/authActions";
import {Link, NavLink} from "react-router-dom";

class Sidebar extends Component {

    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };

    render() {
        //const { user } = this.props.auth;
        return (
            <div className="border-right h-100" id="sidebar-wrapper">
                <div className="list-group list-group-flush">
                    <NavLink to="/dashboard" className="list-group-item list-group-item-action">Dashboard</NavLink>
                    {localStorage.getItem("name") === "admin" ? 
                    <NavLink to="/users" className="list-group-item list-group-item-action">Admin</NavLink> : <></>}
                    <NavLink to="/launchpad" className="list-group-item list-group-item-action">LaunchPad</NavLink>
                     <NavLink to="/about" className="list-group-item list-group-item-action">Home</NavLink>
                     <NavLink to="/homepages" className="list-group-item list-group-item-action">HomePages</NavLink>
                     <NavLink to="/homeimage" className="list-group-item list-group-item-action">HomeImage</NavLink>
                     <Link to="/feature" className="list-group-item list-group-item-action">Image Text Edit</Link> 
                    <Link to="/featruedes" className="list-group-item list-group-item-action">Road More Text</Link>
                    <Link to="/stake" className="list-group-item list-group-item-action">Road More</Link>
                    {/* <Link to="/setting" className="list-group-item list-group-item-action">Settings</Link> */}
                    <Link to="/news" className="list-group-item list-group-item-action">News</Link>
                    <Link to="/newsdescription" className="list-group-item list-group-item-action">News Description</Link>
                    {/* <Link to="/contactus" className="list-group-item list-group-item-action">Contact Us</Link> */}
                    <Link to="/articles" className="list-group-item list-group-item-action">Latest Articles</Link>
                    <Link to="/articles1" className="list-group-item list-group-item-action">Articles1 Image</Link>
                    <Link to="/articles2" className="list-group-item list-group-item-action">Articles2 Image</Link>
                    <Link to="/articles3" className="list-group-item list-group-item-action">Articles3 Image</Link>
                    <Link to="/articles4" className="list-group-item list-group-item-action">Articles4 Image</Link>
                    <button className="list-group-item list-group-item-action" onClick={this.onLogoutClick}>Logout <FontAwesomeIcon icon={faSignOutAlt} /></button>
                </div>
            </div>
        );
    }
}

Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Sidebar);

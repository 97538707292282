import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addFormdata } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import  Web3 from 'web3';
import {ABI,masterchef,FactoryABI,factory,abi,ByteCode} from '../partials/Config';

import 'react-toastify/dist/ReactToastify.css';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false, 
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class FormAddModal extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
             id: "",
            Title: "",
            Heading: "",
            Description:"",
            content: "",
            errors: {},
        };
    }
    componentDidMount(props) {
        console.log("data>>>>>>> :", this.props)

    }


    componentWillReceiveProps(nextProps) {
        //console.log("next Props>>>>",nextProps)
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                Title: nextProps.record.Title,
                Heading: nextProps.record.Heading,
                Description: nextProps.record.Description,
                content: nextProps.record.content,
               
            })
            // console.log("data 2 : ",nextProps.record.quoteTokenSymbol)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {

            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        $('#update-form-modal').modal('hide');

    }

    onChange = e => {


        if (e.target.id === 'Title') {
            this.setState({ Title: e.target.value });
        }
        if (e.target.id === 'Heading') {
            this.setState({ Heading: e.target.value });
        }
        if (e.target.id === 'Description') {
            this.setState({ Description: e.target.value });
        }
        if (e.target.id === 'content') {
            this.setState({ content: e.target.value });
        }



    };
    
    onFormUpdate = e => {
        e.preventDefault();
        const addForm = {
            // _id: this.state.id,
            
            Title: this.state.Title,
            Heading: this.state.Heading,
            Description: this.state.Description,
            content: this.state.content,


        };
         console.log("updateForm___", addForm);
        this.props.addFormdata(addForm);
        $('#add-form-modal').modal('hide');
    };
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-form-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Farm</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate  id="add-form">
                                {/* <div className="row mt-2">
                                    
                                        <div className="col-md-3">
                                            <label htmlFor="alloc">Multipier</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.alloc}
                                                placeholder="Multipier (x)"
                                                id="alloc"
                                                type="text"
                                                error={errors.alloc}
                                                className={classnames("form-control", {
                                                    invalid: errors.alloc
                                                })}/>
                                            <span className="text-danger">{errors.alloc}</span>
                                    </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.pid}
                                                id="pid"
                                                type="hidden"
                                                error={errors.pid}
                                                className={classnames("form-control", {
                                                    invalid: errors.pid
                                                })}/>
                                            <span className="text-danger">{errors.pid}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="Title">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Title}
                                                placeholder="Enter Token Symbol"
                                                id="Title"
                                                type="text"
                                                error={errors.Title}
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })}/>
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="Heading">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                placeholder="Enter Quote Token Symbol"
                                                value={this.state.Heading}
                                                id="Heading"
                                                type="text"
                                                error={errors.Heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}/>
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.Description}
                                                error={errors.Description}
                                                id="Description"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">content</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.content}
                                                error={errors.content}
                                                id="content"

                                                className={classnames("form-control", {
                                                    invalid: errors.content
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.content}</span>
                                        </div>


                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses"> Token A Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                placeholder="Enter Token Address"
                                                value={this.state.tokenAddresses}
                                                error={errors.tokenAddresses}
                                                id="tokenAddresses"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenAddresses
                                                })}
                                            />
                                            <span className="text-danger">{errors.tokenAddresses}</span>
                                        </div>
                                    </div> */}
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Token B Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                placeholder="Enter Quote Token Address"
                                                value={this.state.quoteTokenAdresses}
                                                error={errors.quoteTokenAdresses}
                                                id="quoteTokenAdresses"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.quoteTokenAdresses
                                                })} 
                                            />
                                            <span className="text-danger">{errors.quoteTokenAdresses}</span>
                                        </div>
                                    
                                       
                                    </div> */}
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee"> Deposit Fee</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                placeholder="Enter Deposit Fee"
                                                value={this.state.depositFee}
                                                error={errors.depositFee}
                                                id="depositFee"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.depositFee
                                                })}
                                            />
                                            <span className="text-danger">{errors.depositFee}</span>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                // placeholder="Enter Deposit Fee"
                                                value={this.state.lpAddresses}
                                                error={errors.lpAddresses}
                                                id="lpAddresses"
                                                type="hidden"
                                                className={classnames("form-control", {
                                                    invalid: errors.lpAddresses
                                                })} depositFee
                                            />
                                            <span className="text-danger">{errors.lpAddresses}</span>
                                        </div>
                                    
                                       
                                    </div> */}
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Add Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div> */}
                                    
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="add-form"
                                    
                                    className="btn btn-primary" onClick={this.onFormUpdate}>
                                    Add Farm
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FormAddModal.propTypes = {
    addForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addFormdata }
)(withRouter(FormAddModal));

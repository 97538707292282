import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateIP } from "../../actions/ipAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class IpUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            identifier:this.props.identifier,
            Title:this.props.Title,
            Heading:this.props.record.Heading,
            Description: this.props.record.Description,
            errors: {},
            file:{},
        }
    }
    componentDidMount(props){
        console.log("data :",this.props.record)
    }

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                identifier:nextProps.record.identifier,
                Title:nextProps.record.Title,
               Heading:nextProps.record.Heading,
               Description: nextProps.record.Description,
            //    file: nextProps.record.file,
               
            })

        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log("data 2 : ",nextProps.auth)
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {
           
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
            $('#update-ipaddress-modal').modal('hide');
        
    }

    // onFileChange = event => {
    //     var name = event.target.value;
    //        // Update the state
    //        this.setState({ file: event.target.files[0]});
    //        this.setState({ imagepath: name});
          
    //      };
    onChange = e => {
        if (e.target.id === 'identifier') {
            this.setState({ identifier: e.target.value });
          }
        if (e.target.id === 'Title') {
            this.setState({ Title: e.target.value });
          }
        if (e.target.id === 'Heading') {
             this.setState({ Heading: e.target.value });
           }
           if (e.target.id === 'Description') {
               this.setState({ Description: e.target.value });
           }
          
   
           
   
       };
   
    onIPUpdate = e => {
        e.preventDefault();
        const updateIp = {
            _id: this.state.id,
            identifier:"Landing_page3",
            Title:this.state.Title,
            Heading:this.state.Heading,
            Description:this.state.Description,

           
             };
        this.props.updateIP(updateIp);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-ipaddress-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update </h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onIPUpdate} id="update-ip">
                                    <input
                                        onChange={(e)=>this.onChange(e)}
                                        value={this.state.id}
                                        id="ip-update-id"
                                        type="text"
                                        className="d-none"/>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenSymbol">identifier</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={"Landing_page3"}
                                                id="identifier"
                                                type="text"
                                                error={errors.identifier}
                                                className={classnames("form-control", {
                                                    invalid: errors.identifier
                                                })} />
                                            <span className="text-danger">{errors.identifier}</span>
                                        </div>
                                    </div>
                                  <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Title}
                                                error={errors.Title}
                                                id="Title"
                                                type="text"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })}
                                            />
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading}
                                                id="Heading"
                                                type="text"
                                                error={errors.Heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}/>
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.Description}
                                                id="Description"
                                                type="text"
                                                error={errors.Description}
                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}/>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>
                                    </div>
                                  
                         </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-ip"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

IpUpdateModal.propTypes = {
    updateIP: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateIP }
)(withRouter(IpUpdateModal));

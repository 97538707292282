import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import IpAddressAddModal from "../partials/IpAddressAddModal";
import IPAddressUpdateModal from "../partials/IpAddressUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import backurl  from "../../lib/config";

class Ipaddress extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                // key: "_id",
                text: "S.No",
                className: "id",
                align: "left",
                sortable: true,
                cell: 
                    (record,index) => index+1
                
        },
        {
            key:"Title",
            text: "Title",
            className: "Tittle",
            align: "left",
            sortable: true,
           
        },
          
            {
                key: "Heading",
                text: "Heading",
                className: "Heading",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-ipaddress-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}}>
                                <i className="fa fa-edit"></i>
                            </button>
                          
                           
                        </Fragment>
                    );
                }
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Ipaddress",
            no_data_text: 'No Ip address found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "<<",
                    previous: "<",
                    next: ">",
                    last: ">>"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id:"",
                Heading:"",
                Description:"",
               
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .get(backurl+"/api/get/featuredes")
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
    }


    

    editRecord(record) {
       
        this.setState({ currentRecord: record});
    }

   

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <IpAddressAddModal/>
                    <IPAddressUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/* <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button> */}
                            {/* <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-ipaddress-modal"><FontAwesomeIcon icon={faPlus}/> Add IP Address</button> */}
                            <h1 className="mt-2 text-primary">Road More Edit</h1>
                            <div className="responsive-table">
                                <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            /></div>
                            
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Ipaddress.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Ipaddress);

import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { updatePool } from "../../actions/poolActions";
import {homepageUpdate} from "../../actions/poolActions"
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Web3 from 'web3';
import { ABI, masterchef, FactoryABI, factory, abi, ByteCode } from './Config';

import 'react-toastify/dist/ReactToastify.css';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class HomepagesUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            identifier: this.props.record.identifier,
            id: this.props.record._id,
            Title: this.props.record.Title,
            Heading: this.props.record.Heading,
            Description: this.props.record.Description,
            content: this.props.record.content,
            file: {},


            errors: {},
        };
    }
    componentDidMount(props) {
        console.log("data :", this.props.record)


    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                identifier: nextProps.record.identifier,
                id: nextProps.record._id,
                Title: nextProps.record.Title,
                Heading: nextProps.record.Heading,
                Description: nextProps.record.Description,
                content: nextProps.record.content,
                file: nextProps.record.file,
            })
            // console.log("data 2 : ",nextProps.record.quoteTokenSymbol)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {

            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        $('#update-pool-modal').modal('hide');
    }

    onChange = e => {


        if (e.target.id === 'Title') {
            this.setState({ Title: e.target.value });
        }
        if (e.target.id === 'Heading') {
            this.setState({ Heading: e.target.value });
        }
        if (e.target.id === 'Description') {
            this.setState({ Description: e.target.value });
        }
        if (e.target.id === 'content') {
            this.setState({ content: e.target.value });
        }




    };
    // onFileChange = event => {
    //     var name = event.target.value;
    //     // Update the state
    //     this.setState({ file: event.target.files[0] });
    //     this.setState({ imagepath: name });
    //     console.log(event.target.files[0]);
    // };
    onFileChange = event => {
        var name = event.target.value;
        // Update the state
        var file = event.target.files[0]

        let fileName = file.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile == "png" || extFile =="svg") {
            this.setState({ file: event.target.files[0] });
        }

        else {
            toast("Allowed Only PNG File", {
                position: toast.POSITION.TOP_CENTER
            });
        }

    };

    onPoolUpdate = e => {
        e.preventDefault();
        const updatePool = {
            identifier: "Landing_page2",
            _id: this.state.id,
            Title: this.state.Title,
            Heading: this.state.Heading,
            Description: this.state.Description,
            content: this.state.content,
            file: this.state.file

        };
        console.log("updatePoolupdatePool",updatePool);
         this.props.homepageUpdate(updatePool);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-pool-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update </h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate id="update-pool">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenSymbol">identifier</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={"Landing_page2"}
                                                id="identifier"
                                                type="text"
                                                error={errors.identifier}
                                                className={classnames("form-control", {
                                                    invalid: errors.identifier
                                                })} />
                                            <span className="text-danger">{errors.identifier}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenSymbol">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Title}
                                                id="Title"
                                                type="text"
                                                error={errors.Title}
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })} />
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenSymbol">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading}
                                                error={errors.Heading}
                                                id="Heading"
                                                required
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses ">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.Description}
                                                error={errors.Description}
                                                id="Description"
                                                type="text"
                                                required

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            />
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses ">content</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.content}
                                                error={errors.content}
                                                id="content"
                                                type="text"
                                                required

                                                className={classnames("form-control", {
                                                    invalid: errors.content
                                                })}
                                            />
                                            <span className="text-danger">{errors.content}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>


                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="update-pool"
                                    type="submit"
                                    className="btn btn-primary" onClick={this.onPoolUpdate}>
                                    Update Pool
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

HomepagesUpdate.propTypes = {
    homepageUpdate: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { homepageUpdate }
)(withRouter(HomepagesUpdate));

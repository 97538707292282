import axios from "axios";
import isEmpty from "is-empty";
import backurl from "../lib/config"
import {
    GET_ERRORS,
    POOL_ADD,
    POOL_UPDATE
} from "./types";

export const addPool = (param, history) => dispatch => {
    var poolData = new FormData();
    poolData.append('risk',5 );
    poolData.append('pid',0);
    poolData.append('lpSymbol',param.tokenSymbol+'-' +'BUSD'+' LP');
    poolData.append('alloc',param.alloc*100 );
    poolData.append('isTokenOnly',true );
    poolData.append('lpAddresses',param.lpAddresses );
    poolData.append('tokenSymbol',param.tokenSymbol );
    poolData.append('tokenAddresses',param.tokenAddresses );
    poolData.append('quoteTokenSymbol',"BUSD");
    poolData.append('quoteTokenAdresses',"0xbCf448aa88d80C1B7bb8Fb5A1e68cd4B6c11c354" );
    poolData.append('depositFee',param.depositFee );
    poolData.append('file',param.file );

//console.log("formdata>>>>>>>",formData)
    axios
        .post(backurl+"/api/pool-add", poolData)
        .then(res =>{   
            // console.log("yyyhyu>>",res)
            dispatch({
                type: POOL_ADD,
                payload: res,
            })
        }
        ).catch(err =>{   
            // console.log("yyyhyu>>",err)
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
    }
    );
};

export const updatePool = (param) => dispatch => {
    // console.log("param_param_paramv",param);
   
    var poolUpdateData = new FormData();
    if (!isEmpty(param.identifier))   poolUpdateData.append('identifier',param.identifier );
    if (!isEmpty(param._id))   poolUpdateData.append('_id',param._id );
    if (!isEmpty(param.Title)) poolUpdateData.append('Title',param.Title );
    if (!isEmpty(param.Heading)) poolUpdateData.append('Heading',param.Heading );
    if (!isEmpty(param.Description))poolUpdateData.append('Description',param.Description );
    if (!isEmpty(param.content))poolUpdateData.append('content',param.content );
    if (!isEmpty(param.file)) {
        poolUpdateData.append('file',param.file );
       
    }
    else {
        console.log('222222222');
    }
    
    axios
        .post(backurl+"/api/update/feature", poolUpdateData)
        .then(res =>
            dispatch({
                type: POOL_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const homepageUpdate = (param) => dispatch => {
   
   
    var poolUpdateData = new FormData();
    if (!isEmpty(param.identifier))   poolUpdateData.append('identifier',param.identifier );
    if (!isEmpty(param._id))   poolUpdateData.append('_id',param._id );
    if (!isEmpty(param.Title)) poolUpdateData.append('Title',param.Title );
    if (!isEmpty(param.Heading)) poolUpdateData.append('Heading',param.Heading );
    if (!isEmpty(param.Description))poolUpdateData.append('Description',param.Description );
    if (!isEmpty(param.content))poolUpdateData.append('content',param.content );
    if (!isEmpty(param.file)) {
        poolUpdateData.append('file',param.file );
       
    }
    else {
        console.log('222222222');
    }
    
    axios
        .post(backurl+"/api/update/homepage", poolUpdateData)
        .then(res =>
            dispatch({
                type: POOL_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
export const Homeimageupdate = (param) => dispatch => {
    console.log("param_param_paramv",param);
   
    var poolUpdateData = new FormData();
  
    if (!isEmpty(param._id))   poolUpdateData.append('_id',param._id );
    if (!isEmpty(param.file)) {
        poolUpdateData.append('file',param.file );
       
    }
    else {
        console.log('222222222');
    }
    
    axios
        .post(backurl+"/api/update/homeimage", poolUpdateData)
        .then(res =>
            dispatch({
                type: POOL_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};

